<template>
  <div>
    <h1>Songs: {{ songCategory }}</h1>
    <v-data-table
      dense
      :loading="loading"
      :headers="tableHeaders"
      :items="tableContent"
      :footer-props="tableFooter"
      :search="tableSearch"
      class="mt-5 shaped"
    >
      <template v-slot:top="{ pagination, options, updateOptions }">
        <v-row>
          <v-col cols="4">
            <v-text-field
              v-model="tableSearch"
              label="Suche"
              class="mx-2"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-data-footer
              :pagination="pagination"
              :options="options"
              @update:options="updateOptions"
              :show-current-page="true"
              :show-first-last-page="true"
              :items-per-page-options="tableFooter.itemsPerPageOptions"
              :items-per-page-text="tableFooter.itemsPerPageText"
            ></v-data-footer>
          </v-col>
        </v-row>
      </template>
      <template v-slot:body="{ items }">
        <tbody>
        <tr v-for="(song, idx) in items" :key="idx">
          <td v-for="header in tableHeaders" :key="header.text">
            <!-- COPY TO CLIPBOARD -->
            <span v-if="header.text === 'Clipboard'">
              <v-btn light small class="copy-btn" @click="copyToClipboard($event, getValue(song, header))">
                <v-icon>mdi-content-copy</v-icon>
                &nbsp;Copy
              </v-btn>
            </span>
            <!-- DEFAULT VALUE -->
            <span v-else>
              {{ getValue(song, header) }}
            </span>
          </td>
        </tr>
        </tbody>

      </template>
      
    </v-data-table>
  </div>
</template>

<script>
import axios from "axios";

const webServer = process.env.VUE_APP_WEB_SERVER

export default {
  name: "SongList",
  data() {
    return {
      loading: false,
      songCategory: '-/-',
      tableSearch: '',
      tableHeaders: [
        {
          text: '#',
          align: 'start',
          value: 'index'
        },
        {
          text: 'Interpert',
          value: 'interpret'
        },
        {
          text: 'Titel',
          value: 'titel'
        },
        {
          text: 'Jahr',
          value: 'jahr'
        },
        {
          text: 'Clipboard',
          value: 'copy'
        },
      ],
      tableContent: [],
      tableFooter: {
        itemsPerPageOptions: [25, 50, 100, -1],
        showCurrentPage: true,
        showFirstLastPage: true,
        itemsPerPageText: 'Einträge pro Seite'
      },
    }
  },
  computed: {
    getValue() {
      return (item, header) => {
        return item[header.value]
      }
    }
  },
  methods: {
    async copyToClipboard(event, song) {
      console.log(event.target.style.color="lightgreen")
      const copyText = `!song ${song}`
      await navigator.clipboard.writeText(copyText)
      setTimeout(() => {
        event.target.style.color="white"
      }, 500)
    },
    async requestSongList() {
      const { data: songData } = await axios.get(`${webServer}/json/songs.json`)
      if (Object.keys(songData).length) {
        this.tableContent = songData.song
        this.songCategory = songData.category
        this.tableContent.forEach((song, index) => {
          song.index = index + 1
        })
      }
      this.loading = false
    }
  },
  created() {
    this.loading = true
    this.requestSongList()
  }
}
</script>

<style scoped>
.copy-btn {
  background-color: #2b4764 !important;
  color: #ffffff;
}
.theme--dark.v-data-table tbody tr:nth-of-type(odd) {
  background-color: #303030;
}
</style>