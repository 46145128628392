<template>
  <v-navigation-drawer
    app
    color="bgSecondary"
    v-model="showNav"
  >
    <!-- FLOPPZ image & twitch link -->
    <v-list-item style="height: 64px" href="https://twitch.tv/floppz" target="_blank">
      <v-list-item-content>
        <v-list-item-title>
          <v-card-title class="white--text ml-n4">
            <v-avatar size="56">
              <img
                alt="Floppz"
                src="/floppz_img.png"
              >
            </v-avatar>
            <div class="ml-3 mt-2">
              <div class="text-uppercase">Floppz</div>
              <div style="font-size: x-small" class="mt-n3">(auf Twitch)</div>
            </div>
          </v-card-title>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-divider></v-divider>
    <!-- PAGE navigation -->
    <v-list
      dense
    >
      <v-list-item
        v-for="(menuItem, idx) in menuItems"
        :key="menuItem.text"
        :to="menuItem.link"
      >
        <v-list-item-icon>
          <v-icon v-if="idx === 0" class="error--text">{{ menuItem.icon }}</v-icon>
          <v-icon v-else>{{ menuItem.icon }}</v-icon>
        </v-list-item-icon>
    
        <v-list-item-content>
          <v-list-item-title v-if="idx === 0" class="error--text">{{ menuItem.text }}</v-list-item-title>
          <v-list-item-title v-else>{{ menuItem.text }}</v-list-item-title>
        </v-list-item-content>
        </v-list-item>
    </v-list>
    <!-- TO MOD MENU -->
    <v-list dense v-if="userIsMod">
      <v-list-item :to="{ name: 'ModMenu' }" class="modMenu">
        <v-list-item-icon>
          <v-icon>mdi-shield-crown-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            Admin Panel
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-divider></v-divider>
    <!-- TWITCH login / profil -->
    <v-list dense>
      <v-list-item class="twitchLogin">
        <v-list-item-icon>
          <v-icon color="twitch">mdi-twitch</v-icon>
        </v-list-item-icon>
        <v-list-item-content v-show="!user.isLoggedIn">
          <v-list-item-title @click="toTwitchLogin">
            Login with twitch
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-content v-show="user.isLoggedIn">
          <v-list-item-title @click="toUserProfile">
            {{ user.userData.display_name }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-divider></v-divider>
    <!-- TWITCH logout -->
    <v-list dense v-show="user.isLoggedIn">
      <v-list-item class="twitchLogout">
        <v-list-item-icon>
          <v-icon color="error">mdi-twitch</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title @click="toTwitchLogout">
            Logout
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import {userStore} from "@/store/user";
import {globStore} from "@/store/glob";

export default {
  name: "FloppzNavigation",
  setup() {
    const user = userStore()
    const glob = globStore()
    return {
      user,
      glob
    }
  },
  data() {
    return {
      showNav: true,
      loginDisabled: false,
      menuItems: [
        {
          text: 'Wichtige Informationen',
          icon: 'mdi-information',
          link: { name: 'Info' }
        },
        {
          text: 'User Befehle',
          icon: 'mdi-account',
          link: { name: 'UserCommands' }
        },
        {
          text: 'Chat Spiele',
          icon: 'mdi-forum-outline',
          link: { name: 'ChatGames' }
        },
        {
          text: 'Stream Spiele',
          icon: 'mdi-controller-classic',
          link: { name: 'PlayedGames' }
        },
        {
          text: 'Droppz',
          icon: 'mdi-periodic-table',
          link: { name: 'Droppz' }
        },
        {
          text: 'Aktien',
          icon: 'mdi-chart-line',
          link: { name: 'Shares' }
        },
        {
          text: 'Song (Datenbank)',
          icon: 'mdi-music',
          link: { name: 'Songs' }
        },
        {
          text: 'Marbles GP',
          icon: 'mdi-google-earth',
          link: { name: 'MarblesGrandPrix' }
        },
        {
          text: 'Hilfe / Datenschutz',
          icon: 'mdi-help-circle-outline',
          link: { name: 'Help' }
        }
      ]
    }
  },
  computed: {
    userIsMod() {
      return this.glob.getUserIsMod(this.user.userData.id)
    },
  },
  methods: {
    showNavigation(value) {
      this.showNav = value
    },
    toTwitchLogin() {
      this.user.loginTwitch()
    },
    toTwitchLogout() {
      this.user.logoutTwitch()
    },
    toUserProfile() {
      this.$router.push({ name: 'UserProfile' })
    }
  }
}
</script>

<style scoped>
.img-inline {
  display: inline-block;
}
.modMenu {
  color: #2196f3;
}
.modMenu:hover {
  background-color: #2196f3;
  color: #ffffff;
  cursor: pointer;
}
.twitchLogin:hover {
  background-color: #772ce8;
  cursor: pointer;
}
.twitchLogout:hover {
  background-color: #db5754;
  cursor: pointer;
}
</style>