<template>
 <div>
   <v-row>
     <v-col>
       <h1>Wichtige Information die uns allen das Leben erleichtert.</h1>
       <h3>Lest diese aufmerksam, sie könnten euch vor einem timeout oder ban bewahren!</h3>
       <br>
       <p>
         Wie auch schon in den Streampanels von Floppz ersichtlich, gelten bestimmte Regeln.<br>
         Um sicher zu gehen sind hier nochmal ein paar aufgelistet.
       </p>
     </v-col>
   </v-row>
   <!-- COMMAND SPAM -->
   <v-row>
     <v-col>
       <v-card
         shaped
         outlined
         elevation="2"
         color="bgSecondary"
       >
         <v-card-title class="content-bg-error font-weight-bold">
           <v-icon>mdi-alert-octagon-outline</v-icon> &nbsp; Spammen von Commands!
         </v-card-title>
         <v-card-text class="mt-3">
           Licht, TTS &amp; Co sollen nicht exzessiv eingesetzt werden!
         </v-card-text>
       </v-card>
     </v-col>
   </v-row>
   <!-- MOD BATTLE -->
   <v-row>
     <v-col>
       <v-card
         shaped
         outlined
         elevation="2"
         color="bgSecondary"
       >
         <v-card-title class="content-bg-error font-weight-bold">
           <v-icon>mdi-alert-octagon-outline</v-icon> &nbsp; Mods herausfordern!
         </v-card-title>
         <v-card-text class="mt-3">
           Meine Mods entscheiden in meinem Namen!<br>
           <small>(mess with the best and die like the rest)</small>
         </v-card-text>
       </v-card>
     </v-col>
   </v-row>
   <!-- BAD LANGUAGE -->
   <v-row>
     <v-col>
       <v-card
         shaped
         outlined
         elevation="2"
         color="bgSecondary"
       >
         <v-card-title class="content-bg-error font-weight-bold">
           <v-icon>mdi-alert-octagon-outline</v-icon> &nbsp; YO BRUDI WAS GEHT!
         </v-card-title>
         <v-card-text class="mt-3">
           Bleibt bitte bei einer menschlichen und vernünftigen Ausdrucksweise.<br>
           Habt ihr dazu keine Lust, folgen <code>/timeout &lt;Ghetto-Sprache&gt;</code> <small>(Die Mods entscheiden über die Dauer)</small><br>
           oder gar ein Ban <small>(Hier ist die Dauer meist permanent und nicht verhandelbar)</small>
         </v-card-text>
       </v-card>
     </v-col>
   </v-row>
   <!-- FOLLOW WARNING -->
   <v-row>
     <v-col>
       <v-card
         shaped
         outlined
         elevation="2"
         color="bgSecondary"
       >
         <v-card-title class="content-bg-warning font-weight-bold">
           <v-icon>mdi-alert-outline</v-icon> &nbsp; Follow / Defollow
         </v-card-title>
         <v-card-text class="mt-3">
           Überlegt euch bitte vorher genau ob ihr folgen wollt oder ob das aus "Zwang/Langeweile" passiert ist.<br>
           Ein Follow nur im subgifts abzugreifen schadet zwar nicht dem Streamer, aber ggf. Leuten die schon länger dabei sind.<br>
           Im Falle eines Follow/Defollow besteht die Möglichkeit eines bans. (Kurz nach aktivem Subgift definitiv!)<br>
           Solltet ihr gebannt worden sein und findet das nicht fair => schreibt uns an, wir lassen mit uns reden<br>
           <br>
           Sollte auf den Ban als Trotz ein Refollow/Defollow Spam erfolgen landet ihr unverzüglich auf der Ignore Liste!<br>
           Das heißt ihr könnt von nun an nur noch zusehen <small>(sofern ihr wollt)</small> und dabei bleibt es auch!
         </v-card-text>
       </v-card>
     </v-col>
   </v-row>
   <!-- EXPLANATION -->
   <v-row>
     <v-col>
       <v-card
         shaped
         outlined
         elevation="2"
         color="bgSecondary"
       >
         <v-card-title class="content-bg-normal font-weight-bold">
           <v-icon>mdi-information-outline</v-icon> &nbsp; Erklärung
         </v-card-title>
         <v-card-text class="mt-3">
           Wir sind eine tolle Truppe, das soll auch so bleiben!<br>
           Insbesondere pflegen wir eine sozial angebrachte Umgangsform miteinander!<br>
           <br>
           Die Mods werden alles Mögliche unternehmen um Ruhe und Frieden im Chat zu bewahren.<br>
           Solltet ihr einmal nicht mit einer Entscheidung der Mods einverstanden sein,<br>
           so könnt ihr sie gerne darauf ansprechen.<br>
           Sie werden euch dann erklären, ob und was ihr falsch gemacht habt.
         </v-card-text>
       </v-card>
     </v-col>
   </v-row>
    <!--  -->
   <v-row>
     <v-col>
       Solltet ihr Fragen haben oder möchtet etwas anmerken, dann wendet euch bitte an die Mods.<br>
       Sie sind haupts&auml;chlich f&uuml;r die Community da und werden sich euren Anliegen annehmen.<br>
       <small><code>/mods</code> im twitch chat um alle mods einzusehen - alternatv direkt im discord</small>
     </v-col>
   </v-row>
 </div>
</template>

<script>
export default {
  name: "ImportantInformations"
}
</script>

<style scoped>

</style>