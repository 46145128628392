<template>
  <div>
    <v-card color="bgSecondary" shaped v-if="user.isLoggedIn">
      <v-card-title>
        <v-avatar size="56">
          <img
            v-if="user.userData.profile_image_url"
            :src="user.userData.profile_image_url"
            :alt="user.userData.display_name + '_profil'"
          >
        </v-avatar>&nbsp;
        <h2>{{ user.userData.display_name }}</h2>
      </v-card-title>
      <v-card-subtitle>
        Stream Daten (Droppz, Aktien, usw.)
      </v-card-subtitle>
      <v-card-text>
        <v-divider class="mb-3"></v-divider>
        <v-row>
          <v-col>
            Subscriber:
            <span v-html="getSubInfo"></span>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col>
            Droppz: {{ userDroppz }} <img class="subemote" alt="subemote" :src="require('@/static/droppz.png')">
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col>
            Aktien: <span v-html="userAktien"></span>
          </v-col>
        </v-row>
        <v-row no-gutters v-show="aktienWert">
          <v-col>
            Aktien in Droppz: {{ aktienWert }} <img class="subemote" alt="subemote" :src="require('@/static/droppz.png')">
          </v-col>
        </v-row>
        <v-row no-gutters v-show="aktienWert">
          <v-col>
            Droppz Gesamt: {{ userDroppz + aktienWert }} <img class="subemote" alt="subemote" :src="require('@/static/droppz.png')">
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col>
            Aktuelles Los: {{ userLottoLos }}
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col>
            Dauerlos: {{ userDauerlos }}
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card color="bgSecondary" v-else>
      <v-card-title>
        <v-avatar size="56">
          <v-icon x-large>mdi-account-question-outline</v-icon>
        </v-avatar>&nbsp;
        <h2>Nicht eingeloggt.</h2>
      </v-card-title>
      <v-card-subtitle>
        Bitte erst einloggen...
      </v-card-subtitle>
      <v-card-text>
        <v-divider class="mb-3"></v-divider>
        <v-btn
          class="twitchLogin"
          @click="user.loginTwitch()"
        >
          <v-icon color="twitch">mdi-twitch</v-icon>&nbsp;
          Login with twitch
        </v-btn>
      </v-card-text>
    </v-card>
    <br>
    <v-btn v-if="userIsMod" @click="openSongCheck()">
      <v-icon>mdi-music</v-icon>&nbsp;
      Songcheck
    </v-btn>
  </div>
</template>

<script>
import {userStore} from "@/store/user";
import {droppzStore} from "@/store/droppz";
import {globStore} from "@/store/glob";
import {aktienStore} from "@/store/aktien";

export default {
  name: "UserProfile",
  setup() {
    const user = userStore()
    const droppz = droppzStore()
    const glob = globStore()
    const aktien = aktienStore()
    return {
      user,
      droppz,
      glob,
      aktien
    }
  },
  data() {
    return {
      aktienWert: 0
    }
  },
  computed: {
    userIsMod() {
      return this.glob.getUserIsMod(this.user.userData.id)
    },
    getSubInfo() {
      if (this.user.isSub) {
        let img = `<img class="subemote" alt="subemote" src="${require('@/static/emotes/sub0.png')}">`
        switch (this.user.isSub) {
          case 1:
            img = `<img class="subemote" alt="subemote" src="${require('@/static/emotes/sub1.png')}">`
            break
          case 2:
            img = `<img class="subemote" alt="subemote" src="${require('@/static/emotes/sub2.png')}">`
            break
          case 3:
            img = `<img class="subemote" alt="subemote" src="${require('@/static/emotes/sub3.png')}">`
            break
        }
        return `Stufe ${this.user.isSub} ${img}`
      }
      return 'Nein'
    },
    userDroppz() {
      if (this.droppz.userDroppz.user) {
        const userDroppz = this.droppz.getUserDroppz
        const usersDroppz = userDroppz.find(droppz => droppz.user.toLowerCase() === this.user.userData.display_name.toLowerCase())
        if (usersDroppz?.droppz) {
          return usersDroppz.droppz
        }
        return 0
      }
      return 'Droppz konnten nicht geladen werden'
    },
    userAktien() {
      this.resetAktienWert()
      if (this.glob.aktien && this.user.userData.id > 0) {
        const aktienArr = []
        const userAktien = this.glob.getUserAktien
        const latestAktien = this.aktien.aktienAktuell
        if (userAktien && latestAktien) {
          const usersAktie = userAktien[this.user.userData.id]
          if (usersAktie) {
            usersAktie.Aktie.forEach((aktien, index) => {
              if (aktien > 0) {
                const aktienAbk = this.aktien.aktienDesc[index].Abk
                const aktienValue = latestAktien['aktie' + (index + 1)] * aktien
                this.aktienWert += aktienValue
                aktienArr.push(`${aktienAbk}: ${aktien}`)
              }
            })
            if (aktienArr.length) {
              return aktienArr.join(' | ')
            }
          }
        }
      }
      return 'Keine Aktien'
    },
    userLottoLos() {
      let lottoLos = 'Kein aktuelles Los'
      if (this.glob.lotto && this.user.userData.id > 0) {
        if (this.glob.lotto.NaechsteZiehung && this.glob.lotto.NaechsteZiehung.Lose && this.glob.lotto.NaechsteZiehung.Lose[this.user.userData.id]) {
          lottoLos = this.glob.lotto.NaechsteZiehung.Lose[this.user.userData.id]
        }
      }
      return lottoLos
    },
    userDauerlos() {
      let lottoLos = 'Kein Dauerlos'
      if (this.glob.lotto && this.user.userData.id > 0) {
        if (this.glob.lotto.Dauerlos && this.glob.lotto.Dauerlos.Lose && this.glob.lotto.Dauerlos.Lose[this.user.userData.id]) {
          lottoLos = `${this.glob.lotto.Dauerlos.Lose[this.user.userData.id]}`
        }
      }
      return lottoLos
    }
  },
  methods: {
    openSongCheck() {
      const userId = this.user.userData.id
      window.open(`http://cybex.has.nolife.org/floppz/songcheck.cgi?UID=${userId}`,'_blank')
    },
    resetAktienWert() {
      this.aktienWert = 0
    }
  },
  async created() {
    await this.glob.fetchGlobData()
    if (!this.aktien.aktienDesc.length) {
      await this.aktien.fetchAktienDesc()
      await this.aktien.fetchAktienValues()
    }
    if (!this.droppz.userDroppz.user) {
      await this.droppz.fetchUserDroppz()
    }
  }
}
// TODO:
// Mod userprofile check
// watchtime
// lastseen
// etc.
// using query paramter
// https://floppz.de/#/profile?user=smicer777
</script>

<style scoped>
:deep() .subemote {
  width: 12px;
}
.twitchLogin:hover {
  background-color: #772ce8;
  cursor: pointer;
}
</style>