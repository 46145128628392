<template>
  <div>
    <h1>
      <span v-show="parseInt(droppz.userDroppz.totaldiff) > 0">
        <v-icon color="green">mdi-arrow-up</v-icon>
      </span>
      <span v-show="parseInt(droppz.userDroppz.totaldiff) === 0">
        <v-icon color="orange">mdi-equal</v-icon>
      </span>
      <span v-show="parseInt(droppz.userDroppz.totaldiff) < 0">
        <v-icon color="red">mdi-arrow-down</v-icon>
      </span>
      Total Droppz: {{ droppz.userDroppz.totaldroppz }} <small>({{ droppz.userDroppz.totaldiff}})</small>
    </h1>
    <h3>
      <span v-show="parseInt(droppz.userDroppz.jackdiff) > 0">
        <v-icon color="green">mdi-arrow-up</v-icon>
      </span>
      <span v-show="parseInt(droppz.userDroppz.jackdiff) === 0">
        <v-icon color="orange">mdi-equal</v-icon>
      </span>
      <span v-show="parseInt(droppz.userDroppz.jackdiff) < 0">
        <v-icon color="red">mdi-arrow-down</v-icon>
      </span>
      Jackpot: {{ droppz.userDroppz.jackpot }} <small>({{ droppz.userDroppz.jackdiff}})</small> /
      Aktien: {{ droppz.userDroppz.aktien }} /
      Lotto: {{ droppz.userDroppz.lotto }}
    </h3>
    <v-data-table
      dense
      :loading="loading"
      :headers="tableHeaders"
      :items="droppz.userDroppz.user"
      :footer-props="tableFooter"
      :search="tableSearch"
      id="droppz"
      class="mt-5 shaped"
    >
      <template v-slot:top="{ pagination, options, updateOptions }">
        <v-row>
          <v-col cols="4">
            <v-text-field
              v-model="tableSearch"
              label="Suche"
              class="mx-2"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-data-footer
              :pagination="pagination"
              :options="options"
              @update:options="updateOptions"
              :show-current-page="true"
              :show-first-last-page="true"
              :items-per-page-options="tableFooter.itemsPerPageOptions"
              :items-per-page-text="tableFooter.itemsPerPageText"
            ></v-data-footer>
          </v-col>
        </v-row>
      </template>
      <template v-slot:body="{ items }">
        <tbody>
        <tr v-for="(user, idx) in items" :key="idx">
          <td v-for="header in tableHeaders" :key="header.text">
            <!-- COPY TO CLIPBOARD -->
            <span v-if="header.text === 'Tendenz'">
              <span v-show="user.diff > 0">
                <v-icon color="green">mdi-arrow-up</v-icon>
              </span>
              <span v-show="user.diff === 0">
                <v-icon color="orange">mdi-equal</v-icon>
              </span>
              <span v-show="user.diff < 0">
                <v-icon color="red">mdi-arrow-down</v-icon>
              </span>
              {{ user.diff }} ({{ user.placediff }})
            </span>
            <!-- DEFAULT VALUE -->
            <span v-else>
              {{ getValue(user, header) }}
            </span>
          </td>
        </tr>
        </tbody>
      </template>
    </v-data-table>
    <div ref="droppzChart" class="chartDiv"></div>
  </div>
</template>

<script>
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import {droppzStore} from "@/store/droppz";

export default {
  name: "DroppzOverview",
  setup() {
    const droppz = droppzStore()
    return {
      droppz
    }
  },
  data() {
    return {
      loading: false,
      reloading: false,
      tableSearch: '',
      tableHeaders: [
        {
          text: '#',
          align: 'start',
          value: 'index'
        },
        {
          text: 'User',
          value: 'user'
        },
        {
          text: 'Droppz',
          value: 'droppz',
          align: 'start',
        },
        {
          text: 'Tendenz',
          value: 'diff'
        },
      ],
      tableFooter: {
        itemsPerPageOptions: [25, 50, 100, -1],
        showCurrentPage: true,
        showFirstLastPage: true,
        itemsPerPageText: 'Einträge pro Seite'
      }
    }
  },
  computed: {
    getValue() {
      return (item, header) => {
        return item[header.value]
      }
    }
  },
  methods: {
    async requestDroppz() {
      await this.droppz.fetchDroppzData()
      this.loading = false
    },
    createChart(retry = 0) {
      const chartDiv = this.$refs.droppzChart
      // https://www.amcharts.com/docs/v5/concepts/colors-gradients-and-patterns/
      const chartRoot = am5.Root.new(chartDiv)
      chartRoot.setThemes([
        am5themes_Animated.new(chartRoot)
      ])
      chartRoot.interfaceColors.set("text", am5.color('#ffffff'))
    
      const chart = chartRoot.container.children.push(am5xy.XYChart.new(chartRoot, {
        cursorTooltipEnabled: true,
      }))
    
      const cursor = chart.set("cursor", am5xy.XYCursor.new(chartRoot, {
        behavior: "none"
      }))
      cursor.lineY.set("visible", false);
    
      const xAxis = chart.xAxes.push(am5xy.DateAxis.new(chartRoot, {
        maxDeviation: 0.2,
        baseInterval: {
          timeUnit: "minute",
          count: 5
        },
        renderer: am5xy.AxisRendererX.new(chartRoot, {}),
        tooltip: am5.Tooltip.new(chartRoot, {})
      }))
      const yAxis = chart.yAxes.push(am5xy.ValueAxis.new(chartRoot, {
        renderer: am5xy.AxisRendererY.new(chartRoot, {opposite: true}),
        tooltip: am5.Tooltip.new(chartRoot, {})
      }))
    
      const seriesChart = chart.series.push(am5xy.LineSeries.new(chartRoot, {
        name: "Series",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: 'value',
        valueXField: "date",
        cursorTooltipEnabled: false,
        tooltip: am5.Tooltip.new(chartRoot, {
          labelText: "{valueY}"
        }),
      }))
    
      seriesChart.strokes.template.setAll({
        strokeWidth: 3
      })
    
      chart.set("scrollbarX", am5.Scrollbar.new(chartRoot, {
        orientation: "horizontal"
      }))
    
      seriesChart.data.setAll(this.droppz.chartDroppz)
    
      seriesChart.appear(1000, 200)
      chart.appear(1000, 200)
    
      this.droppzChart = chartRoot
      this.seriesChart = seriesChart
      
      if (!this.droppzChart && retry <= 6) {
        setTimeout(() => {
          retry++
          this.createChart(retry)
        }, 1000 * retry)
      }
    },
    destroyChart() {
      this.droppzChart.dispose()
    },
    updateChart() {
      this.seriesChart.data.setAll(this.droppz.chartDroppz)
    }
  },
  mounted() {},
  async created() {
    this.loading = true
    await this.requestDroppz()
    await this.createChart()
    this.reloading = setInterval(async () => {
      this.loading = true
      await this.requestDroppz()
      await this.updateChart()
    }, 1000 * 60 * 5)
  },
  beforeDestroy() {
    clearInterval(this.reloading)
    this.destroyChart()
  }
}
</script>

<style scoped>
.theme--dark.v-data-table tbody tr:nth-of-type(odd) {
  background-color: #303030;
}
.chartDiv {
  bottom: 0;
  width: 100%;
  height: 500px;
}
</style>