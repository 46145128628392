import { render, staticRenderFns } from "./ImportantInformations.vue?vue&type=template&id=44e9dc6a&scoped=true&"
import script from "./ImportantInformations.vue?vue&type=script&lang=js&"
export * from "./ImportantInformations.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44e9dc6a",
  null
  
)

export default component.exports