import { defineStore } from 'pinia'
import axios from "axios"

const twitchAPI = 'https://api.twitch.tv/helix'
const clientId = 'iufdsvbo5swqiviqv2einlkb89rqh1'

export const userStore = defineStore('userStore', {
  state: () => {
    return {
      userData: {
        id: 0,
        login: '',
        display_name: '',
        email: '',
        profile_image_url: ''
      },
      isSub: false,
      cookieData: {
        token: '',
        expires_in: '',
        refresh_token: ''
      }
    }
  },
  actions: {
    clearUser() {
      this.userData = {
        id: 0,
        login: '',
        display_name: '',
        email: '',
        profile_image_url: ''
      }
    },
    axiosCfg(userToken) {
      if (!userToken) {
        userToken = this.cookieData.token
      }
      return {
        headers: {
          'Client-Id': clientId,
          'Authorization': `Bearer ${userToken}`
        }
      }
    },
    cookieHandler(responseData) {
      const zeit = new Date(new Date().getTime() + responseData.expires_in * 1000)
      document.cookie = `token=${responseData.access_token}`
      document.cookie = `refresh_token=${responseData.refresh_token}`
      document.cookie = `expires_in=${zeit}`
      this.setCookieData()
    },
    setCookieData() {
      const cookieArray = document.cookie.split(';')
      cookieArray.forEach(cookie => {
        cookie = cookie.trim()
        const cookieArr = cookie.split('=')
        this.cookieData[cookieArr[0]] = cookieArr[1]
      })
      return this.cookieData.token;
    },
    async loginTwitch() {
      if (!this.userData.id > 0) {
        window.location = `https://id.twitch.tv/oauth2/authorize?response_type=code&client_id=${clientId}&redirect_uri=https://epiq.fail/twitch/login&scope=user:read:email+user:read:subscriptions`
      }
      else {
        await this.refreshToken()
      }
    },
    async logoutTwitch() {
      await axios.post(`https://id.twitch.tv/oauth2/revoke?client_id=${clientId}&token=${this.cookieData.token}`)
        .catch((error) => {
          alert('Logout war nicht erfolgreich.')
          console.log(error)
        })
      document.cookie = "token=; expires = Thu, 01 Jan 1970 00:00:00 GMT"
      document.cookie = "refresh_token=; expires = Thu, 01 Jan 1970 00:00:00 GMT"
      document.cookie = "expires_in=; expires = Thu, 01 Jan 1970 00:00:00 GMT"
      this.setCookieData()
      await this.clearUser()
    },
    async activateToken(code) {
      return await axios.get(`https://epiq.fail/twitch/login/twitch.php?type=activate&code=${code}`)
        .then(async (response) => {
          if (Array.isArray(response.data) && response.data[0].indexOf('400 Bad Request') !== -1) {
            return false
          }
          else {
            this.cookieHandler(response.data)
            await this.fetchUserData()
            return true
          }
        })
        .catch(error => {
          console.error(error.response)
          return false
        })
    },
    async refreshToken() {
      // TODO: error handling
      if (this.cookieData.refresh_token) {
      await axios.get(`https://epiq.fail/twitch/login/twitch.php?type=refresh&refresh=${this.cookieData.refresh_token}`)
        .then(async response => {
          if (Array.isArray(response.data) && response.data[0].indexOf('400 Bad Request') !== -1) {
            console.log('Could not refresh token - Status 400 Bad Request')
          } else {
            this.cookieHandler(response.data)
            this.setCookieData()
          }
        })
        .catch(error => {
          console.log(error.response)
        })
      }
      else {
        console.log('Could not refresh token, refresh_token is empty')
      }
    },
    async fetchUserInfo() {
      const { data: jsonData } = await axios.get(`${twitchAPI}/users`, this.axiosCfg())
        .catch(async (error) => {
          if (error.response && error.response.status === 401) {
            console.log(error.response.data.message)
            if (error.response.data.message === 'Invalid OAuth token') {
              await this.refreshToken()
            }
          }
          return {}

        })
      if (jsonData) {
        this.userData = jsonData.data[0]
      }
    },
    async isUserSubscriber() {
      if (this.userData.id > 0) {
        const { data: jsonData } = await axios.get(`${twitchAPI}/subscriptions/user?broadcaster_id=16362402&user_id=${this.userData.id}`, this.axiosCfg())
          .catch(async (error) => {
            if (error.response && error.response.status === 401) {
              if (error.response.data.message === 'Invalid OAuth token') {
                await this.refreshToken()
              }
            }
            return {}
          })
        if (jsonData && jsonData.data.length) {
          this.isSub = jsonData.data[0].tier / 1000
        }
      }
    },
    async fetchUserData() {
      await this.fetchUserInfo()
      await this.isUserSubscriber()
    }
  },
  getters: {
    isLoggedIn(state) {
      return state.userData.id > 0
    }
  }
})