import Vue from 'vue'
import VueRouter from 'vue-router'
import ImportantInformations from "@/components/ImportantInformations";
import DroppzOverview from "@/components/DroppzOverview";
import AktienOverview from "@/components/AktienOverview";
import UserCommands from "@/components/UserCommands";
import ChatGames from "@/components/ChatGames";
import PlayedGames from "@/components/PlayedGames";
import SongList from "@/components/SongList";
import HelpPrivacy from "@/components/HelpPrivacy";
import HouseData from "@/components/HouseData";
import MarblesGrandPrix from "@/components/MarblesGrandPrix";
import UserProfile from "@/components/UserProfile";
import TwitchLoginHandler from "@/components/TwitchLoginHandler";
import ModMenu from "@/components/ModMenu.vue";

Vue.use(VueRouter)

const routes = [
  {
    path: '',
    component: ImportantInformations,
    name: 'InfoMain'
  },
  {
    path: '/info',
    component: ImportantInformations,
    name: 'Info'
  },
  {
    path: '/user_commands',
    component: UserCommands,
    name: 'UserCommands'
  },
  {
    path: '/chat_spiele',
    component: ChatGames,
    name: 'ChatGames'
  },
  {
    path: '/stream_spiele',
    component: PlayedGames,
    name: 'PlayedGames'
  },
  {
    path: '/droppz',
    component: DroppzOverview,
    name: 'Droppz'
  },
  {
    path: '/aktien',
    component: AktienOverview,
    name: 'Shares'
  },
  {
    path: '/songs',
    component: SongList,
    name: 'Songs'
  },
  {
    path: '/marbles_gp',
    component: MarblesGrandPrix,
    name: 'MarblesGrandPrix',
    alias: ['/murmeln', '/marbles'],
  },
  {
    path: '/help',
    component: HelpPrivacy,
    name: 'Help'
  },
  {
    path: '/profile',
    component: UserProfile,
    name: 'UserProfile'
  },
  {
    path: '/twitch_login',
    component: TwitchLoginHandler,
    name: 'TwitchLoginHandler'
  },
  {
    path: '/house',
    component: HouseData,
    name: 'HouseData'
  },
  {
    path: '/mod_page',
    component: ModMenu,
    name: 'ModMenu'
  }
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes
})

export default router