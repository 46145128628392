<template>
  <v-app>
    <v-app-bar
      app
      color="floppzSecondary"
    >
      <v-app-bar-nav-icon
        @click="showNavigation"
        class="d-flex d-lg-none"
      ></v-app-bar-nav-icon>
    </v-app-bar>
    <floppz-navigation
      ref="navigation"
    ></floppz-navigation>
    <v-main class="content-bg-primary">
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import FloppzNavigation from "@/layout/FloppzNavigation";
import {userStore} from "@/store/user";
import {aktienStore} from "@/store/aktien";

export default {
  name: 'FloppzPage',
  setup() {
    const user = userStore()
    const aktien = aktienStore()
    return {
      user,
      aktien
    }
  },
  data() {
    return {}
  },
  components: {
    FloppzNavigation
  },
  methods: {
    showNavigation() {
      this.$refs.navigation.showNavigation(true)
    }
  },
  async created() {
    if ((location.protocol !== 'https:' || location.host.startsWith('www')) && process.env.NODE_ENV === 'production') {
      location.href = `https://${location.host.replace('www.', '')}/${location.hash}`
    }
    const hasCookie = this.user.setCookieData()
    if (hasCookie) {
      await this.user.fetchUserData()
    }
    await this.aktien.fetchAktienData()
  }
}
</script>

<style>
.content-bg-primary {
  background-color: #222222
}
.content-bg-secondary {
  background-color: #303030
}
.content-bg-error {
  background-color: #db5754
}
.content-bg-warning {
  background-color: #f39c12
}
.content-bg-normal {
  background-color: #2b4764
}
kbd {
  background-color: #404040 !important;
  color: #5bc0de !important;
}
pre {
  background-color: #404040 !important;
}
code {
  background-color: #404040 !important;
  color: #5bc0de !important;
}
.theme--dark.v-data-table thead {
  background-color: #2b4764;
}
.shaped {
  border-radius: 24px 4px !important;
}
</style>
