<template>
  <div>
    <h1>Spiele</h1><h3>(gespielt / vorgeschlagen)</h3>
    <v-data-table
      dense
      :loading="tableLoading"
      :headers="tableHeaders"
      :footer-props="tableFooter"
      :search="tableSearch"
      :items="tableContent"
      class="mt-5 shaped"
      sort-by="last"
      sort-desc
    >
      <template v-slot:top="{ pagination, options, updateOptions }">
        <v-row>
          <v-col cols="4">
            <v-text-field
              v-model="tableSearch"
              label="Suche"
              class="mx-2"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-data-footer
              :pagination="pagination"
              :options="options"
              @update:options="updateOptions"
              :show-current-page="true"
              :show-first-last-page="true"
              :items-per-page-options="tableFooter.itemsPerPageOptions"
              :items-per-page-text="tableFooter.itemsPerPageText"
            ></v-data-footer>
          </v-col>
        </v-row>
      </template>
      <template v-slot:body="{ items }">
        <tbody>
        <tr v-for="(game, idx) in items" :key="idx">
          <td v-for="header in tableHeaders" :key="header.text">
            <!-- GAME TEXT -->
            <span v-if="header.text === 'Spiel'" v-html="game.name">
            </span>
            <!-- LINK TO ICONS -->
            <span v-else-if="header.text === 'Links'" v-html="linksToIcon(game)">
            </span>
            <!-- DATE TO READABLE -->
            <span v-else-if="header.text === 'Spielzeit'">
              {{ timeReadable(game) }} Stunden
            </span>
            <!-- MINUTES TO HOURS -->
            <span v-else-if="header.text === 'Zuletzt gespielt'">
              {{ dateReadable(game) }}
            </span>
            <!-- DEFAULT VALUE -->
            <span v-else>
              {{ getValue(game, header) }}
            </span>
          </td>
        </tr>
        </tbody>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import axios from "axios";

const webServer = process.env.VUE_APP_WEB_SERVER

export default {
  name: "PlayedGames",
  data() {
    return {
      tableLoading: false,
      tableSearch: '',
      tableHeaders: [
        {
          text: '#',
          value: 'index',
          align: 'start'
        },
        {
          text: 'Spiel',
          value: 'name'
        },
        {
          text: 'Vorgeschlagen von',
          value: 'user'
        },
        {
          text: 'Spielzeit',
          value: 'minutes'
        },
        {
          text: 'Zuletzt gespielt',
          value: 'last'
        },
        {
          text: 'Links',
          value: 'links'
        }
      ],
      tableFooter: {
        itemsPerPageOptions: [25, 50, 100, -1],
        showCurrentPage: true,
        showFirstLastPage: true,
        itemsPerPageText: 'Einträge pro Seite'
      },
      tableContent: []
    }
  },
  computed: {
    getValue() {
      return (item, header) => {
        return item[header.value] ? item[header.value] : '-'
      }
    },
    timeReadable() {
      return (item) => {
        const time = item.minutes ? parseInt(item.minutes) : 0
        return Math.floor(time / 60)
      }
    },
    dateReadable() {
      return (item) => {
        let date = item.last
        if (date) {
          const dateObj = new Date(date)
          return dateObj.toLocaleTimeString('de-DE', {day: '2-digit', month: '2-digit', year: 'numeric' })
        }
        return '-'
      }
    },
    linksToIcon() {
      return (item) => {
        const linksArray = []
        item.links.forEach(link => {
          let linkHtml = `<a href="${link}" target="_blank">`
          if (link.match(/mmoga.de/gi)) {
            // if (link.match(/\?/g) || !link.match(/\?ref=57027&partner=floppz/ig)) {
            //   link = link.split('?')[0]
            //   linkHtml = `<a href="${link}?ref=57027&Partner=floppz" target="_blank">`
            // }
            // if (!link.match(/\?ref=57027&partner=floppz/ig)) {
            //   linkHtml = `<a href="${link}?ref=57027&Partner=floppz" target="_blank">`
            // }
            linkHtml += `<img class="img-link" alt="mmoga" src="${require('@/static/mmoga.png')}">`
          } else if (link.match(/steampowered.com/gi)) {
            linkHtml += `<img class="img-link" alt="steam" src="${require('@/static/steam.png')}">`
          } else if (link.match(/epicgames.com/gi)) {
            linkHtml += `<img class="img-link" alt="steam" src="${require('@/static/epic.png')}">`
          } else if (link.match(/gog.com/gi)) {
            linkHtml += `<img class="img-link" alt="steam" src="${require('@/static/gog.png')}">`
          } else {
            linkHtml += `<img class="img-link" alt="link" src="${require('@/static/link.png')}">`
          }
          linkHtml += `</a>`
          linksArray.push(linkHtml)
        })
        return linksArray.join('')
      }
    }
  },
  methods: {
    async getGamesList() {
      const { data: gameData } = await axios.get(`${webServer}/json/games.json`)
      if (gameData.length) {
        this.tableContent = gameData
        this.tableContent.forEach((game, index) => {
          game.index = index + 1
        })
      }
      this.tableLoading = false
    }
  },
  created() {
    this.tableLoading = true
    this.getGamesList()
  },
}
</script>

<style scoped>
:deep() .img-link {
  margin-top: 6px;
  margin-right: 14px;
  width: 26px !important;
}
a {
  text-decoration: none !important;
}
.theme--dark.v-data-table tbody tr:nth-of-type(odd) {
  background-color: #303030;
}
</style>