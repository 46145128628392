import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify)

const opts = {
  theme: {
    dark: true,
    themes: {
      dark: {
        bgPrimary: '#222222',
        bgSecondary: '#303030',
        bgThird: '#404040',
        bgInfo: '#2b4764',
        bgInfoText: '#5bc0de',
        bgWarning: '#f39c12',
        bgError: '#db5754',
        floppzPrimary: '#0012bf',
        floppzSecondary: '#2b4764',
        floppzError: '#db5754',
        floppzWarning: '#dba154',
        twitch: '#472e75',
      },
      // light: {
      //   background: '#505050',
      //   bgPrimary: '#222222',
      //   bgSecondary: '#303030',
      //   bgThird: '#404040',
      //   bgInfo: '#5bc0de',
      //   bgWarning: '#f39c12',
      //   bgError: '#db5754',
      //   floppzPrimary: '#0012bf',
      //   floppzSecondary: '#0018ff',
      //   floppzError: '#db5754',
      //   floppzWarning: '#dba154',
      //   twitch: '#472e75',
      // },
    },
  },
}

export default new Vuetify(opts)