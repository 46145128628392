import { render, staticRenderFns } from "./HelpPrivacy.vue?vue&type=template&id=3f39f970&scoped=true&"
import script from "./HelpPrivacy.vue?vue&type=script&lang=js&"
export * from "./HelpPrivacy.vue?vue&type=script&lang=js&"
import style0 from "./HelpPrivacy.vue?vue&type=style&index=0&id=3f39f970&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f39f970",
  null
  
)

export default component.exports