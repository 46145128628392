<template>
  <div>
    <v-row>
      <v-col>
        <h1>User commands</h1>
        <v-card
          shaped
          color="bgWarning"
        >
          <v-card-text
            class="white--text"
          >
          <strong>Alle Befehle haben einen Cooldown für die entsprechende Usergruppe. Missbrauch von Commands wird geahndet!</strong>
          </v-card-text>
        </v-card>
        <h5 class="mt-5">
          Da wir so viele Commands haben sind aktuell nicht alle gelistet<br>
          <strong>(Commands die den Stream direkt beeinflussen kosten Droppz! - Eine Preisliste wird ggf. folgen)</strong>
        </h5>
      </v-col>
    </v-row>
    
    <v-row>
      <v-col>
        <v-expansion-panels accordion multiple>
          <v-expansion-panel>
            <v-expansion-panel-header color="bgSecondary">
              <v-row>
                <v-col xs="12" sm="3" md="1" cols="12">
                  Chat Spiele
                </v-col>
                <v-col>
                  <v-chip
                    small
                    outlined
                    color="bgInfoText"
                  >
                    für jeden mit genug Droppz
                  </v-chip>
                </v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content color="bgThird">
              <v-simple-table class="mt-4">
                <thead>
                <tr>
                  <th style="width: 20%">Command</th>
                  <th>Aktion</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td><kbd>!duell User Betrag</kbd></td>
                  <td>
                    Startet eine Duellanfrage an den User mit einem frei wählbaren Droppz Betrag. (Limits: mindestens 10 - maximal 50000)<br>
                    Beispiel: !duell Floppz 100
                  </td>
                </tr>
                <tr>
                  <td><kbd>!slots Betrag [Runden]</kbd></td>
                  <td>
                    Startet den Einarmigen Banditen. (Limits: mindestens 10 - maximal 5000)<br>
                    Beispiele:<br>
                    <kbd>!slots 200 </kbd> (für eine Runde slots mit einem Einsatz von 200 Droppz.)<br>
                    <kbd>!slots 200 2</kbd> (für zwei Runden slots mit dem Einsatz von 100 Droppz pro Runde)
                  </td>
                </tr>
                <tr>
                  <td>
                    <kbd>!lotto # # # #</kbd><br>
                    <kbd>!lotto zufall</kbd>
                  </td>
                  <td>
                    Wähle deine Lottozahlen für unser 4 aus 20. Ziehung Dienstag & Donnerstag 13 Uhr.<br>
                    Beispiele:<br>
                    <kbd>!lotto 1 4 8 20</kbd> (für ein Los mit den Zahlen 1 4 8 20)<br>
                    <kbd>!lotto zufall</kbd> (wählt wie der Name schon sagt, zufällige Zahlen aus)
                  </td>
                </tr>
                <tr>
                  <td>
                    <kbd>!dauerlos # # # #</kbd><br>
                    <kbd>!dauerlos zufall</kbd>
                  </td>
                  <td>
                    Damit wird automatisch ein neues Lotto Los gekauft für jede Ziehung gekauft (sofern der Droppz Kontostand es erlaubt).<br>
                    Beispiele:<br>
                    <kbd>!dauerlos 1 4 8 20</kbd> (für ein Dauerlos mit den Zahlen 1 4 8 20)<br>
                    <kbd>!dauerlos zufall</kbd> (wählt wie der Name schon sagt, zufällige Zahlen aus)
                  </td>
                </tr>
                <tr>
                  <td><kbd>!aktienindex</kbd></td>
                  <td>Zeigt den Aktienindex und die aktuellen Kurse</td>
                </tr>
                <tr>
                  <td>
                    <kbd>!aktie kauf Aktienname Menge</kbd><br>
                    <kbd>!aktie verkauf Aktiennname Menge</kbd>
                  </td>
                  <td>
                    Kauft Aktien von Name und legt sie in dein Depot. Beispiel: !aktie kauf mpx 10<br>
                    Verkauft Aktien von Name. Beispiel: !aktie verkauf chaosnerd 7
                  </td>
                </tr>
                <tr>
                  <td><kbd>!boss [hp]</kbd></td>
                  <td>
                    Ist ein Bosskampf offen, kann man sich damit am Kampf beteiligen.<br>
                    Wird keine HP angeben, beteiligt man sich mit 100. (Limits: mindestens 10 - maximal: 1000)<br>
                    Beispiele:<br>
                    <kbd>!boss 150</kbd> (für Bosskampf beitritt mit 150 HP)<br>
                    <kbd>!boss 1000</kbd> (für Bosskampf beitritt mit 1000 HP)
                  </td>
                </tr>
                <tr>
                  <td><kbd>!locken [chance]</kbd></td>
                  <td>
                    Damit kann ein Boss aus dem Gebüsch vorgelockt werden.<br>
                    Wird keine Chance angegeben, wird als standard 10 genommen. (Limits: mindestens 10 - maximal: 100)<br>
                    Beispiele:<br>
                    <kbd>!locken</kbd> (lockt mit einer "standard" Chance von 10)<br>
                    <kbd>!locken 50</kbd> (lockt mit einer Chance von 50)
                  </td>
                </tr>
                </tbody>
              </v-simple-table>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header color="bgSecondary">
              Sound Commands
            </v-expansion-panel-header>
            <v-expansion-panel-content color="bgThird">
              <v-simple-table class="mt-4">
                <thead>
                <tr>
                  <th style="width: 20%">Command</th>
                  <th>Aktion</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td><kbd>!rip</kbd></td>
                  <td>Ist er etwa schon wieder gestorben?</td>
                </tr>
                <tr>
                  <td><kbd>!bekloppt</kbd></td>
                  <td>Seid ihr alle total ..</td>
                </tr>
                <tr>
                  <td><kbd>!verhört</kbd></td>
                  <td>Hat er nicht gesagt. Oder etwa doch?</td>
                </tr>
                <tr>
                  <td><kbd>!haha</kbd></td>
                  <td>Hat er mal wieder was dummes getan? Damit könnt ihr ihn auslachen.</td>
                </tr>
                <tr>
                  <td><kbd>!arbeit</kbd></td>
                  <td>Die Leute kommen einfach nicht ihrer Arbeit nach...</td>
                </tr>
                <tr>
                  <td><kbd>!ausrede</kbd></td>
                  <td>Versucht er sich wieder rauszureden?</td>
                </tr>
                <tr>
                  <td><kbd>!flieht</kbd></td>
                  <td>ihr Narren</td>
                </tr>
                <tr>
                  <td><kbd>!naklar</kbd></td>
                  <td>Na klar!</td>
                </tr>
                <tr>
                  <td><kbd>!jaja</kbd></td>
                  <td>Jaja heißt ....</td>
                </tr>
                <tr>
                  <td><kbd>!houston</kbd></td>
                  <td>hat ein Problem</td>
                </tr>
                <tr>
                  <td><kbd>!panik</kbd></td>
                  <td>schnell Handeln</td>
                </tr>
                <tr>
                  <td><kbd>!alarm</kbd></td>
                  <td>Bi Do Bi Do</td>
                </tr>
                <tr>
                  <td><kbd>!feuerwehr</kbd></td>
                  <td>Alarm bei der Feuerwehr</td>
                </tr>
                <tr>
                  <td><kbd>!applaus</kbd></td>
                  <td>Klatschen</td>
                </tr>
                <tr>
                  <td><kbd>!jubel</kbd></td>
                  <td>Jubeln</td>
                </tr>
                <tr>
                  <td><kbd>!hunger</kbd></td>
                  <td>Essen nötig</td>
                </tr>
                <tr>
                  <td><kbd>!durst</kbd></td>
                  <td>Trinken nötig</td>
                </tr>
                <tr>
                  <td><kbd>!stroh</kbd></td>
                  <td>Warum hast du eine Maske auf?</td>
                </tr>
                </tbody>
              </v-simple-table>
              <h4>Begrüßung und Verabschiedung</h4>
              <v-simple-table class="mt-4">
                <thead>
                <tr>
                  <th style="width: 20%">Command</th>
                  <th>Aktion</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td><kbd>!moin</kbd></td>
                  <td>Pro Tag geht nur EINE dieser Begrüßungen!</td>
                </tr>
                <tr>
                  <td><kbd>!eygude</kbd></td>
                  <td></td>
                </tr>
                <tr>
                  <td><kbd>!ggott</kbd></td>
                  <td></td>
                </tr>
                <tr>
                  <td><kbd>!servus</kbd></td>
                  <td></td>
                </tr>
                <tr>
                  <td><kbd>!palim</kbd></td>
                  <td></td>
                </tr>
                <tr>
                  <td><kbd>!halloo</kbd></td>
                  <td></td>
                </tr>
                <tr>
                  <td><kbd>!tag !tach</kbd></td>
                  <td></td>
                </tr>
                <tr>
                  <td><kbd>!lords</kbd></td>
                  <td></td>
                </tr>
                <tr>
                  <td><kbd>!ladies</kbd></td>
                  <td></td>
                </tr>
                <tr>
                  <td><kbd>!ehre</kbd></td>
                  <td>Verabschiedung vom Chat und Floppz, geht auch nur EINE einmal am Tag</td>
                </tr>
                <tr>
                  <td><kbd>!fliegen</kbd></td>
                  <td></td>
                </tr>
                <tr>
                  <td><kbd>!tschüß</kbd></td>
                  <td></td>
                </tr>
                <tr>
                  <td><kbd>!bye</kbd></td>
                  <td></td>
                </tr>
                <tr>
                  <td><kbd>!afk</kbd></td>
                  <td>Nur für MODs</td>
                </tr>
                </tbody>
              </v-simple-table>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header color="bgSecondary">
              Nicht Sub Commands
            </v-expansion-panel-header>
            <v-expansion-panel-content color="bgThird">
              <v-simple-table class="mt-4">
                <thead>
                <tr>
                  <th style="width: 20%">Command</th>
                  <th>Aktion</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td><kbd>!dr / !droppz</kbd></td>
                  <td>Gibt dir Informationen über dein Floppz Droppz Konto</td>
                </tr>
                <tr>
                  <td><kbd>!dr / !droppz Floppz</kbd></td>
                  <td>Gibt dir Informationen über den Kontostand von Floppz (kann auf jeden User angewendet werden)</td>
                </tr>
                <tr>
                  <td><kbd>!dr / !droppz geben Username Betrag</kbd></td>
                  <td>Beispiel: !dr geben Floppz 100 gibt <b>Floppz 100</b> Droppz. Kann auf jeden User angewendet werden,
                    Droppz Menge ebenfalls frei wählbar.
                  </td>
                </tr>
                <tr>
                  <td><kbd>!marbles</kbd></td>
                  <td>Trägt dich in die Marbles Liste ein. Weitere Befehle, mit denen der Satz beginnen muss sind <kbd>!marbel,
                    !murmel, !klickern</kbd></td>
                </tr>
                <tr>
                  <td><kbd>!tts deine Nachricht die gelesen werden soll</kbd></td>
                  <td>Lässt Alexa deine Nachricht vorlesen</td>
                </tr>
                <tr>
                  <td><kbd>!multi</kbd></td>
                  <td>Gibt den aktuelle Link zum Multistream aus (falls aktiv).</td>
                </tr>
                <tr>
                  <td><kbd>!onair</kbd></td>
                  <td>Schaltet das onair Schild aus oder ein</td>
                </tr>
                <tr>
                  <td><kbd>!blau</kbd></td>
                  <td>Taucht das Zimmer von Floppz in seine Lieblingsfarbe - Blau</td>
                </tr>
                <tr>
                  <td><kbd>!grün</kbd></td>
                  <td>Färbt das Zimmer von Floppz Grün</td>
                </tr>
                <tr>
                  <td><kbd>!türkis</kbd></td>
                  <td>Färbt das Zimmer von Floppz Türkis</td>
                </tr>
                <tr>
                  <td><kbd>!orange</kbd></td>
                  <td>Färbt das Zimmer von Floppz Orange</td>
                </tr>
                <tr>
                  <td><kbd>!gelb</kbd></td>
                  <td>Färbt das Zimmer von Floppz Gelb</td>
                </tr>
                </tbody>
              </v-simple-table>
              <br>
              <div class="card text-white">
                <div class="card-body bg-primary">
                  <v-chip
                    small
                    label
                    color="bgPrimary"
                  >
                    <strong>
                      Alle Farbcommands müßen am Anfang der Sätze verwendet werden. Als Beispiel: <kbd>!blau mag Floppz am
                      liebsten</kbd>
                    </strong>
                  </v-chip>
                </div>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header color="bgSecondary">
              <v-row>
                <v-col xs="12" sm="3" md="1" cols="12">
                  Sub Commands
                </v-col>
                <v-col>
                  <v-chip
                    small
                    outlined
                    color="bgInfoText"
                  >
                    alle nicht Sub Commands gehen natürlich auch
                  </v-chip>
                </v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content color="bgThird">
              <v-simple-table class="mt-4">
                <thead>
                <tr>
                  <th style="width: 20%">Command</th>
                  <th>Aktion</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td><kbd>!discord</kbd></td>
                  <td>Gibt den Discord Link im Chat aus</td>
                </tr>
                <tr>
                  <td><kbd>!steam</kbd></td>
                  <td>Gibt den Steam Link von Floppz im Chat aus</td>
                </tr>
                <tr>
                  <td><kbd>!epic</kbd></td>
                  <td>Gibt den Epic Namen von Floppz im Chat aus</td>
                </tr>
                <tr>
                  <td><kbd>!wreckfest</kbd></td>
                  <td>Gibt Namen und Passwort vom Wreckfest-Server aus</td>
                </tr>
                <tr>
                  <td><kbd>!pink</kbd></td>
                  <td>Färbt das Zimmer von Floppz Pink</td>
                </tr>
                <tr>
                  <td><kbd>!lila</kbd></td>
                  <td>Färbt das Zimmer von Floppz Lila</td>
                </tr>
                <tr>
                  <td><kbd>!flash</kbd></td>
                  <td>Lichtgewitter bei Floppz im Raum</td>
                </tr>
                </tbody>
              </v-simple-table>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header color="bgSecondary">
              <v-row>
                <v-col xs="12" sm="3" md="1" cols="12">
                  VIP Commands
                </v-col>
                <v-col>
                  <v-chip
                    small
                    outlined
                    color="bgInfoText"
                  >
                    alle nicht Sub &amp; Sub Commands gehen natürlich auch
                  </v-chip>
                </v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content color="bgThird">
              <v-simple-table class="mt-4">
                <thead>
                <tr>
                  <th style="width: 20%">Command</th>
                  <th>Aktion</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td><kbd>!uv</kbd></td>
                  <td>Setzt Floppz für eine gewisse Zeit ins UV-Licht</td>
                </tr>
                <tr>
                  <td><kbd>!blackout</kbd></td>
                  <td>
                    Schaltet das Licht komplett aus
                    <div class="card text-white">
                      <div class="card-body bg-danger">
                        <v-chip
                          small
                          label
                          color="bgError"
                        >
                          <strong>
                            Exzessive Benutzung oder SPAM wird mit Timeout und/oder bot-command Ban bestraft!
                          </strong>
                        </v-chip>
                      </div>
                    </div>
                  </td>
                </tr>
                </tbody>
              </v-simple-table>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
    
  </div>
</template>

<script>
export default {
  name: "UserCommands"
}
</script>

<style scoped>
</style>