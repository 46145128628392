<template>
  <div>
    <h1>Hilfe Hilfe <small>Hilfe Hilfe</small></h1>
    <v-card shaped color="bgThird mt-5">
      <v-card-text class="white--text">
        Für allgemeine Fragen und Probleme könnte ihr euch gerne an Floppz oder die Mods wenden.<br>
        Es lässt sich sicher eine Lösung finden.<br>
        <br>
        Für Fragen zum Bot oder zu dieser Seite bitte an pleX (<a href="http://twitch.tv/pl_ex" target="_blank">pl_eX auf twitch</a>, <a href="https://discord.gg/fMdXgsvMtY" target="_blank">pleX in discord</a>) wenden.
        <br>
        <br>
        Wir verwenden auf unserer Website Twitch, ein Live-Streaming-Portal für Videospiele.<br>
        Dienstanbieter ist das amerikanische Unternehmen Interactive, Inc., 350 Bush Street, 2nd Floor, San Francisco, CA 94104, USA.
      </v-card-text>
    </v-card>
    <v-card shaped color="bgError mt-5">
      <v-card-text>
        <h3 class="white--text">
          <v-icon>mdi-alert-outline</v-icon> ACHTUNG <v-icon>mdi-alert-outline</v-icon>
          <v-divider class="mt-3 mb-3"></v-divider>
          Wir weisen darauf hin, dass nach Meinung des Europäischen Gerichtshofs derzeit kein angemessenes Schutzniveau für den Datentransfer in die USA besteht.<br>
          Die Datenverarbeitung geschieht im Wesentlichen durch Twitch. Dies kann dazu führen, dass gegebenenfalls Daten nicht anonymisiert verarbeitet und gespeichert werden.<br>
          Ferner können gegebenenfalls US-amerikanische staatliche Behörden Zugriff auf einzelne Daten nehmen.<br>
          Es kann ferner vorkommen, dass diese Daten mit Daten aus möglichen anderen Diensten von Twitch, bei denen Sie ein Nutzerkonto haben, verknüpft werden.
        </h3>
        <br>
        <h4 class="white--text">
          Mehr über die Daten, die durch die Verwendung von Twitch verarbeitet werden, erfahren Sie in der Privacy Policy auf<br>
          <a href="https://www.twitch.tv/p/de-de/legal/privacy-notice/" target="_blank" class="twitchlink"><v-icon class="twitchlink">mdi-twitch</v-icon> https://www.twitch.tv/p/de-de/legal/privacy-notice/</a>
        </h4>
      </v-card-text>
    </v-card>
    <v-card shaped color="bgInfo mt-5">
      <v-card-text class="white--text">
        Unsere System verwenden Cookies um bei einem Twitch-Login (optional) erweiterte Funktionalität zu gewährleisten.<br>
        Mit Ausnahme der Twitch Daten, speichern wir keinerlei Daten über die Besucher selbst, weder wo sie herkommen, noch wie lange sie auf der Webseite sind.<br>
        Folgende Daten erhalten und speichern wir von Twitch:<br>
        <v-card>
          <v-card-text>
            <v-row no-gutters>
              <v-col cols="2">session token</v-col>
              <v-col>Der nach dem Login von Twitch zurück gesendete Token</v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="2">refresh token</v-col>
              <v-col>Der nach dem Login von Twitch zurück gesendete Token zum erneuern des session token</v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="2">expire date</v-col>
              <v-col>Der nach dem Login von Twitch zurück gesendete zeitstempel an dem der session token erneuert werden muss</v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <br>
        Folgende Daten erhalten wir von Twitch um Benutzerinformationen nutzen zu können:<br>
        (diese werden <strong>NICHT</strong> gespeichert sondern bei bedarf von Twitch abgefragt)<br>
        <v-card>
          <v-card-text>
            <v-row no-gutters>
              <v-col cols="2">user ID</v-col>
              <v-col>Die ID des Twitch users (eindeutige zuweisung eines Users)</v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="2">user name</v-col>
              <v-col>Der Twitch username (wie er auch im Chat sichtbar ist)</v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="2">subscriber</v-col>
              <v-col>Information ob der User Subscriber bei Floppz ist</v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <br>
        Solltet ihr dennoch bedenken haben den Twitch-Login zu nutzen, so ist das völlig in Ordnung, da der Login freiwillig ist.<br>
        Für weitere Fragen und Anliegen zum Thema Twitch-Login und den dazugehörigen Daten wendet euch an pleX (<v-icon>mdi-arrow-up</v-icon> siehe oben)
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "HelpPrivacy"
}
</script>

<style scoped>
.twitchlink {
  color: #500000;
  text-decoration: none;
}
</style>