<template>
  <div>
    ModPage - Work in Progress
  </div>
</template>

<script>
export default {
  name: "ModMenu"
}
</script>

<style scoped>

</style>